import React, { Component } from 'react';

export default class ExpenseIcon extends Component {
  iconFrom(category) {
    switch (category) {
      case "Lebensmittel":
        return "local_grocery_store";
      case "Restaurant":
        return "local_restaurant";
      case "Auto":
        return "directions_car";
      case "Tanken":
        return "local_gas_station";
      case "Hobbies":
        return "nordic_walking";
      case "Radfahren":
        return "pedal_bike";
      case "Gaming":
        return "gamepad";
      case "Kleidung":
        return "checkroom";
      case "Eintritte":
        return "store";
      case "Shopping":
        return "shopping_cart";
      case "Gesundheit":
        return "local_hospital";
      case "Drogerie":
        return "clean_hands";
      case "Unterhaltung":
        return "subscriptions";
      case "Transport":
        return "directions_subway";
      case "Übernachtung":
        return "local_hotel";
      case "Yvonne":
        return "face";
      case "Haustiere":
        return "pets";
      case "Fixkosten":
        return "payments";
      case "Sonstiges":
        return "compost";
      default:
        return "attach_money";
    }
  }

  render() {
    return (
      <span
        className={`mdc-list-item__start-detail ${this.props.category}`}
        role="presentation"
      >
        <i className="material-icons" aria-hidden="true">
          {this.iconFrom(this.props.category)}
        </i>
      </span>
    );
  }
}
